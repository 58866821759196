import React from "react";
import { Link } from "react-router";
import { Container, Grid, Popup } from "semantic-ui-react";
import logoNegative from "../../assets/vx-logo-negative.png";
import footerPhone from "../../assets/footer-phone.svg";
import footerEmail from "../../assets/footer-email.svg";
import primeLogo from "../../assets/Prime_Fibre_logo_WHITE.png";
import "./style.css";

const mobile = 16;
const tablet = 5;
const computer = 5;

const Footer = (props) => {
  const {
    text,
    linksAlignment,
    textAlignment,
    popupAlignment,
    color,
    textColor,
    release,
  } = props;

  const links =
    props.links &&
    props.links.map((link, i) => {
      if (i < props.links.length - 1) {
        return (
          <span key={link.URL}>
            <Link to={link.URL} style={{ color: textColor }}>
              {link.label}
            </Link>
            ,&nbsp;
          </span>
        );
      }
      return (
        <span key={link.URL}>
          <Link to={link.URL} style={{ color: textColor }}>
            {link.label}
          </Link>
        </span>
      );
    });

  // Return a column structure given an alignment
  const getColumn = (alignment) => (
    <Grid.Column
      className={alignment}
      textAlign="center"
      mobile={mobile}
      tablet={alignment === "center" ? tablet + 1 : tablet}
      computer={alignment === "center" ? computer + 1 : computer}
    >
      {text && textAlignment === alignment && <p>{text}</p>}
      {links && linksAlignment === alignment && links}
    </Grid.Column>
  );

  // Define the main component
  const footer = (
    <div>
      <div className="default-footer">
        <Container>
          <Grid stackable relaxed inverted columns="equal">
            <Grid.Column width={16}>
              <div className="vx-logo">
                <img
                  id="vx-footer-logo"
                  src={logoNegative}
                  alt="vx-footer-logo"
                />
                <img
                  id="grosvenor-footer-logo"
                  src={primeLogo}
                  alt="ADDITIONAL LOGO"
                />
              </div>
              <p>
                This is a partnership between The Grosvenor Estate, Prime Fibre
                and some of the country’s leading service providers. Gigabit
                full fibre network brought to you by Prime Fibre. Proudly
                providing an open-access full fibre network to Mayfair and
                Belgravia.
              </p>
              <h4>Contact us:</h4>
              <ul className="footer-list">
                <li>
                  <img
                    id="vx-footer-logo"
                    src={footerEmail}
                    alt="vx-footer-logo"
                  />{" "}
                  <a href="mailto: info@primefibre.london">
                    info@primefibre.london
                  </a>
                </li>
                <li>
                  <img
                    id="vx-footer-logo"
                    src={footerPhone}
                    alt="vx-footer-logo"
                  />{" "}
                  <a href="tel:02031515004">02031515004</a>
                </li>
                <li>Prime Fibre is a subsidiary of VX Fiber</li>
              </ul>
              <h4>About VX Fiber</h4>
              <p>
                VX Fiber is a Swedish technology company – an FTTH operator and
                wholesale provider with more than 20 years’ experience running
                open-access networks globally.
              </p>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
      <Grid
        className="footer"
        style={{
          background: color,
          color: textColor,
        }}
        stackable
        padded
        columns={3}
      >
        <Grid.Row>
          {getColumn("left")}
          {getColumn("center")}
          {getColumn("right")}
        </Grid.Row>
      </Grid>
    </div>
  );

  // Return the component within a Popup component
  return (
    <Popup
      position={`top ${popupAlignment}`}
      on="click"
      flowing
      hideOnScroll
      trigger={footer}
      content={`Build: ${release.build} - Version: ${release.version}`}
    />
  );
};

export default Footer;
