import base64 from "base-64";

let api_key_param = "";

export const fetchMyProfile = async api_key => {
  let rootURL = JSON.parse(sessionStorage.getItem("config")).api;
  api_key_param = api_key ? `?api_key=${api_key}` : "";
  let init;
  let objectName;

  return fetch(`${rootURL}/api/my_profile${api_key_param}`)
    .then(response => {
      if (response.ok) {
        return response.json().then(response => {
          init = response;

          if (init.role === "autologin") {
            return fetch(`${rootURL}/object?q=id:${response.objects}&fields=object_group.name${api_key_param}`)
              .then(response => {
                if (response.ok) {
                  return response.json().then(response => {
                    objectName = response.data[0]["object_group.name"];
                    init.objectName = objectName;
                    console.log("autologin", init);
                    return init;
                  });
                }
              });
          } else if (init.role === "anonymous") {
            const objectGroupName = JSON.parse(sessionStorage.getItem("config")).object_groups[0];

            return fetch(`${rootURL}/object_group?q=name:${objectGroupName}&fields=id`)
              .then(response => {
                if (response.ok) {
                  return response.json();
                }
              })
              .then(({ data: objectGroups }) => {
                return {
                  ...init,
                  object_groups: objectGroups.map(objectGroup => objectGroup.id),
                }
              });
          } else {
            return init;
          }
        });
      }
      if (response.status >= 100 && response.status < 200) {
        console.log(response.statusText);
      }
      if (response.status >= 300 && response.status < 399) {
        console.log(response.statusText);
      }
      if (response.status >= 400 && response.status < 499) {
        console.log(
          `${response.status} ${
            response.statusText
          } - check fetchMyProfile request`
        );
      }
      if (response.status >= 500 && response.status < 599) {
        console.log(response.statusText);
      }
    })
    .catch(error => console.log("Error fetchMyProfile", error.message));
};

export const fetchObject = async (api_key, object) => {
  let rootURL = JSON.parse(sessionStorage.getItem("config")).api;
  api_key_param = api_key ? `&api_key=${api_key}` : "";

  return fetch(`${rootURL}/object?q=id:${object}&fields=id,status,object_type,street, 
    postal_code,object_number,apartment_number,port,city,street_number,
    area,network_operator,provisioning_status,note,postal_code,latitude,order_status,
    province,object_group,public_note,longitude,object_group.name${api_key_param}`)
    .then(response => {
      if (response.ok) {
        return response.json().then(response => response);
      }
      if (response.status >= 100 && response.status < 200) {
        console.log(response.statusText);
      }
      if (response.status >= 300 && response.status < 399) {
        console.log(response.statusText);
      }
      if (response.status >= 400 && response.status < 499) {
        console.log(
          `${response.status} ${
            response.statusText
          } - check fetchObject request`
        );
        return response;
      }
      if (response.status >= 500 && response.status < 599) {
        console.log(response.statusText);
      }
    })
    .catch(error => console.log("Error fetchObject", error.message));
};

export const fetchOrder = async (api_key, orderData) => {
  let rootURL = JSON.parse(sessionStorage.getItem("config")).api;

  api_key_param = api_key ? `?api_key=${api_key}` : "";

  return fetch(`${rootURL}/order${api_key_param}`, {
    method: "post",
    body: JSON.stringify(orderData)
  })
    .then(response => {
      if (response.ok) {
        return response.json().then(response => response.data);
      }
      if (response.status >= 100 && response.status < 200) {
        console.log(response.statusText);
      }
      if (response.status >= 300 && response.status < 399) {
        console.log(response.statusText);
      }
      if (response.status >= 400 && response.status < 499) {
        console.log(
          `${response.status} ${response.statusText} - check fetchOrder request`
        );
        return response
          .json()
          .then(
            response => (response = { error: true, message: response.message })
          );
      }
      if (response.status >= 500 && response.status < 599) {
        console.log(response.statusText);
      }
    })
    .catch(error => console.log("Error fetchOrder", error.message));
};

export const fetchGetOrder = async api_key => {
  let rootURL = JSON.parse(sessionStorage.getItem("config")).api;

  api_key_param = api_key ? `?api_key=${api_key}` : "";

  return fetch(`${rootURL}/order${api_key_param}`)
    .then(response => {
      if (response.ok) {
        return response.json().then(response => response.data);
      }
      if (response.status >= 100 && response.status < 200) {
        console.log(response.statusText);
      }
      if (response.status >= 300 && response.status < 399) {
        console.log(response.statusText);
      }
      if (response.status >= 400 && response.status < 499) {
        console.log(
          `${response.status} ${
            response.statusText
          } - check fetchGetOrder request`
        );
        return response;
      }
      if (response.status >= 500 && response.status < 599) {
        console.log(response.statusText);
      }
    })
    .catch(error => console.log("Error fetchGetOrder", error.message));
};

export const fetchLoginToken = async data => {
  let rootURL = JSON.parse(sessionStorage.getItem("config")).api;

  let url = `${rootURL}/api/api_key`;
  let username = data.username;
  let password = data.password;
  let headers = new Headers();

  headers.append(
    "Authorization",
    "Basic " + base64.encode(username + ":" + password)
  );

  return fetch(url, {
    method: "get",
    headers: headers
  })
    .then(response => {
      if (response.ok) {
        return response.json().then(response => {
          sessionStorage.setItem("api_key", response.api_key);
          return response;
        });
      }
      if (response.status >= 100 && response.status < 200) {
        console.log(response.statusText);
      }
      if (response.status >= 300 && response.status < 399) {
        console.log(response.statusText);
      }
      if (response.status >= 400 && response.status < 499) {
        console.log(
          `${response.status} ${
            response.statusText
          } - check fetchLoginToken request`
        );
        return response;
      }
      if (response.status >= 500 && response.status < 599) {
        console.log(response.statusText);
      }
    })
    .catch(error => {
      console.log("Error fetchLoginToken", error);
      return error;
    });
};
