import { RECEIVE_LOGIN_DATA, RECEIVE_LOGOUT } from "../actions/login.actions";

export default (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_LOGIN_DATA:
      return data;
    case RECEIVE_LOGOUT:
      return data;
    default:
      return state;
  }
};
