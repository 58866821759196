export const REQUEST_SHOW_MODAL = "REQUEST_SHOW_MODAL";
export const RECEIVE_SHOW_MODAL = "RECEIVE_SHOW_MODAL";

export const REQUEST_HIDE_MODAL = "REQUEST_HIDE_MODAL";

export const requestShowModal = (data, modalType) => ({
  type: REQUEST_SHOW_MODAL,
  showModal: true,
  data,
  modalType
});

export const receiveShowModal = (data, modalType) => ({
  type: RECEIVE_SHOW_MODAL,
  showModal: true,
  data,
  modalType
});

export const requestHideModal = () => ({
  type: REQUEST_HIDE_MODAL
});
