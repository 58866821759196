import { defineMessages } from 'react-intl';

const messages = defineMessages({
  navigation_get_connected: {
    id: 'navigation.get_connected',
    defaultMessage: 'Get connected',
    description: 'Navigation item: get connected',
  },
  navigation_services: {
    id: 'navigation.services',
    defaultMessage: 'Services',
    description: 'Navigation item: services',
  },
  navigation_my_places: {
    id: 'navigation.my_places',
    defaultMessage: 'My places',
    description: 'Navigation item: my places',
  },
  navigation_my_profile: {
    id: 'navigation.my_profile',
    defaultMessage: 'My profile',
    description: 'Navigation item: my profile',
  },
  navigation_faq: {
    id: 'navigation.faq',
    defaultMessage: 'FAQ',
    description: 'Navigation item: faq',
  },
  navigation_signup: {
    id: 'navigation.signup',
    defaultMessage: 'Sign up',
    description: 'Navigation item: signup',
  },
  navigation_logout: {
    id: 'navigation.logout',
    defaultMessage: 'Logout',
    description: 'Navigation item: logout',
  },
});

const items = [
  {
    id: 'get_connected',
    url: 'coverage',
    message: messages.navigation_get_connected,
    roles: ['anonymous', 'autologin', 'customer'],
  },
  {
    id: 'services',
    url: 'services',
    message: messages.navigation_services,
    roles: ['anonymous', 'autologin'],
  },
  {
    id: 'my_places',
    url: 'my-places',
    message: messages.navigation_my_places,
    roles: ['autologin', 'customer'],
  },
  {
    id: 'my_profile',
    url: 'profile',
    message: messages.navigation_my_profile,
    roles: ['customer'],
  },
  {
    id: 'signup',
    url: 'signup',
    message: messages.navigation_signup,
    roles: ['autologin'],
  },
  {
    id: 'contact_us',
    url: 'contact-us',
    icon: 'mail',
    secondary: true,
  },
  {
    id: 'login',
    url: 'login',
    icon: 'user circle',
    secondary: true,
    roles: ['anonymous', 'autologin', 'customer'],
  },
];

export default items;
