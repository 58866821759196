import React, { Component } from 'react';
import { Button, Dimmer, Icon } from 'semantic-ui-react';
import { Link } from 'react-router';
import moment from 'moment';


const injectGAScript = () => {
  const config = JSON.parse(sessionStorage.getItem("config"));

  // Store consent only if is not previously given
  if (!localStorage.getItem("consent")) {
    // TODO for GDPR compliance consent should be stored into DB
    // with an incremental logic 'cause it should be denied and given
    // multiple times from the same user/anonymous and legals has to be able to know exactly when
    localStorage.setItem("consent", 'Given on ' + moment(Date.now()).format('LLL'));
  }

  // Create GTM HEAD script
  const GTMHeadScript = document.createElement("script");

  GTMHeadScript.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':` +
    `new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],` +
    `j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=` +
    `'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);` +
    `})(window,document,'script','dataLayer','${config.google_tm_container_id}');`;

  // Create GTM BODY script elements
  const GTMBodyScript = document.createElement("noscript");
  const GTMiFrame = document.createElement("iframe");

  const iFrameWidth = document.createAttribute("width");
  const iFrameHeight = document.createAttribute("height");
  iFrameWidth.value = "0";
  iFrameHeight.value = "0";

  // Apply attributes to the <iframe>
  GTMiFrame.src = `https://www.googletagmanager.com/ns.html?id=${config.google_tm_container_id}`;
  GTMiFrame.setAttributeNode(iFrameWidth);
  GTMiFrame.setAttributeNode(iFrameHeight);
  GTMiFrame.style.display = "none";
  GTMiFrame.style.visibility = "hidden";

  // <iframe> is built so nest it into <noscript> element
  GTMBodyScript.appendChild(GTMiFrame);

  // Finally append scripts to the DOM
  document.head.appendChild(GTMHeadScript);
  document.body.appendChild(GTMBodyScript);
};

class CookieBanner extends Component {
  constructor(props) {
    super(props);
    this.state = { gtmcookie: undefined };

    this.injectScript = this.injectScript.bind(this);
    this.closeBanner = this.closeBanner.bind(this);
  }

  componentDidMount() {
    // Add Google Tag Manager scripts if consent was previously given
    if (localStorage.getItem("consent")) {
      this.injectScript();
    } else {
      this.setState({ gtmcookie: false });
    }
  }

  injectScript() {
    injectGAScript();
    this.setState({ gtmcookie: true });
  }

  closeBanner() {
    this.setState({ gtmcookie: true });
  }

  render() {
    return (
      <Dimmer active={!this.state.gtmcookie} page>
        <h3>Cookies at VX FIBER</h3>
        <div
          style={{
            maxWidth: '450px',
            textAlign: 'justify',
            marginBottom: '20px',
            lineHeight: '20px',
          }}
        >
          We use cookies to navigate between pages efficiently, remembering your preferences, and generally improve the user experience. By clicking OK you consent to VX FIBRE placing cookies on your device. Further information about the types of cookies VX FIBRE uses can be found in our <Link to="/privacy-policy" style={{ color: "white", textDecoration: "underline" }}>Cookie Policy</Link>.
        </div>
        <Button
          primary
          onClick={this.injectScript}
          content="Ok"
          style={{ padding: '10px 40px' }}
        />
        <Icon
          name="close"
          onClick={this.closeBanner}
          style={{
            position: 'absolute',
            top: '15px',
            right: '10px',
            opacity: '0.7',
            cursor: 'pointer',
          }}
        />
      </Dimmer>
    )
  }
}

export { CookieBanner, injectGAScript };
