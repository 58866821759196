export const REQUEST_ORDER_DATA = "REQUEST_ORDER_DATA";
export const RECEIVE_ORDER_DATA = "RECEIVE_ORDER_DATA";
export const REQUEST_ORDER_GET = "REQUEST_ORDER_GET";
export const RECEIVE_ORDER_GET = "RECEIVE_ORDER_GET";

//ACTION CREATORS
export const requestOrderData = (data, modalType) => ({
  type: REQUEST_ORDER_DATA,
  data
});
export const receiveOrderData = (data, modalType) => ({
  type: RECEIVE_ORDER_DATA,
  showModal: true,
  data,
  modalType
});

//ACTION CREATORS
export const requestOrderGet = () => ({
  type: REQUEST_ORDER_GET
});
export const receiveOrderGet = data => ({
  type: RECEIVE_ORDER_GET,
  data
});
