import React from "react";
import { Segment, Icon, Header, Divider, Button } from "semantic-ui-react";

let ServiceMessage = props => {
  return (
    <div className="service-message">
      <Segment basic textAlign="center">
      	<Divider hidden />
      	<Divider hidden />
			  <Header as="h4" icon>
			    <Icon name="cogs" size="huge" />
			    Connection Problems
			    <Header.Subheader>Check your internet connection or retry later</Header.Subheader>
			  </Header>
			  <Divider hidden />
			  <Button content="Refresh" icon="refresh" onClick={() => window.location.reload()}/>
      </Segment>
    </div>
  );
};

export default ServiceMessage;
