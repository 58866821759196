import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import _ from "lodash";
import AOS from "aos";
import override from "json-override";
import { check } from "is-offline";
import { Link, Element } from "react-scroll";
import { Loader, Dimmer, Segment, Header, Image } from "semantic-ui-react";

// This import is needed to generate messages
// overriding VX portal components messages
import messageOverrides from "../../messageOverrides"; // eslint-disable-line
import { IntlProvider, addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import it from "react-intl/locale-data/it";
import de from "react-intl/locale-data/de";
import es from "react-intl/locale-data/es";
import sv from "react-intl/locale-data/sv";
import ms from "react-intl/locale-data/ms";
import my from "react-intl/locale-data/my";

import { Navigation, Modal } from "portal-components";
import Footer from "../../components/Footer";
import request from "../../utils/config_request";

import { requestShowSpinner, requestHideSpinner } from "../../actions/spinner.actions";
import { requestOrderGet } from "../../actions/order.actions";
import { toggleCustomerType } from "../../actions/customerType.actions";
import { loadLang } from "../../actions/lang.actions";
import { userLang } from "../../actions/userLang.actions";
import { requestLogout } from "../../actions/login.actions";


import items from "./menu_data";
import primeLogo from "../../assets/Prime_Fibre_logo_WHITE.png";
import navigationLogo from "../../assets/logo.png";
import arrowDown from "../../assets/vx-fiber-arrow-down.png";

import "aos/dist/aos.css";
import "./App.css";
import {CookieBanner} from "../../components/CookieBanner";

addLocaleData([...en, ...it, ...de, ...es, ...sv, ...ms, ...my]);
// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them

const residentialBusinessEnabledPaths = ['services'];

class App extends Component {
  config = JSON.parse(sessionStorage.getItem("config"));

  constructor(props) {
    super(props);

    this.state = {
      homePath: true,
      languages: this.config.languages[0].id,
      msg: null,
      showModal: true,
      showCookieBanner: false,
    };

    this.langType = this.langType.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleLogoutClick = this.handleLogoutClick.bind(this);
  }

  componentDidMount() {
    // Checking browser lang with config lang
    this.config.languages.forEach(el => {
      if (navigator.language.substring(0, 2) === el.id) {
        this.props.userLang(el.id);
      } else {
        this.props.userLang(this.config.languages[0].id);
      }
    });

    window.onscroll = () => {
      // Navigation bar has reached the top
      if (window.pageYOffset >= window.innerHeight) {
        this.setState({ showCookieBanner: true });
      }
    }

    Promise.resolve(
      request(`./langs/${this.state.languages}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
    ).then(res => {
      this.props.loadLang(res.data);
      this.setState({ msg: this.props.lang });
    });
    this.props.requestShowSpinner();

    check().then(bool => this.setState({ offNet: bool }));

    AOS.init({
      duration: 800,
      offset: 0,
      easing: 'ease',
    });
  }

  handleLogoutClick = () => {
    const { requestShowSpinner, requestLogout } = this.props;
    
    requestShowSpinner();
    requestLogout();
    
    sessionStorage.removeItem("api_key");
    browserHistory.push(this.config.base_url);
  };

  handleItemClick = (name) => {
    console.log(name);
    // SET TOGGLED CUSTOMER TYPE ON REDUX STORE
    this.props.toggleCustomerType(name);
    // SET TOGGLED CUSTOMER TYPE ON LOCAL STORAGE
    localStorage.setItem("customerType", name); 
  }


  langType(event, data) {
    const value = data.value.toLowerCase();
    if (this.state.languages !== value) {
      Promise.resolve(
        request(`./langs/${value}.json`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        })
      ).then(res => {
        this.props.loadLang(res.data);
        this.setState({
          msg: this.props.lang,
          languages: value
        });
      });
    }
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { location, children, userLang, init, lang, order } = this.props;
    const { languages } = this.state;

    let newItems = items;
    let locale = userLang;
    let finalMessages = lang[languages];
    let modal;
    let content = (
      <Dimmer className="dimmer-fixed" active inverted>
        <Loader>Loading</Loader>
      </Dimmer>
    );

    const links = [
      {
        label: "Privacy policy",
        URL: `${this.config.base_url}privacy-policy`
      },
      {
        label: "Terms and conditions",
        URL: `${this.config.base_url}terms`
      }
    ];

    // Start Language handling
    if (!_.isEmpty(languages)) {
      locale = languages;
    }

    if (this.state.msg) {
      finalMessages = override(finalMessages, this.state.msg[locale], true);
    }

    // Page rendering
    if (init && init.role && !_.isEmpty(lang)) {
      if (init.role === "autologin") {
        content = init.object ? children : "";
      } else {
        content = children;
      }

      if (init.role === "customer" && init.autologin_object) {
        console.log("check active orders of other customers");
        if (!_.isEmpty(order)) {
          order.forEach(order => {
            if (
              order.status === "Activated" ||
              order.status === "AwaitingActivation"
            ) {
              if (order.customer !== init.id) {
                modal = (
                  <Modal
                    headerTitle="Notice"
                    contentText="There are other customers with active services on this connection"
                    dimmer="inverted"
                    open={this.state.showModal}
                    positive
                    action={this.closeModal}
                  />
                );
                console.log(
                  "there are other customers with active services on this object"
                );
              } else {
                console.log("no services of other customers are active here");
              }
            }
          });
        }
      }

      const release = {
        build: JSON.parse(sessionStorage.getItem("build")).build,
        version: JSON.parse(sessionStorage.getItem("build")).version
      };

      if (init.username) {
        newItems = newItems.map(item => (
          item.id !== 'login'
            ? item
            : {
              ...item,
              message: 'logout',
              action: this.handleLogoutClick,
            }
        ));
      }

      return (
        <IntlProvider locale={locale} messages={finalMessages}>
          <div className="App" id="app_wrapper">
            {
              location.pathname === this.config.base_url &&
                <Segment
                  id="home-header"
                  vertical
                  inverted
                  textAlign="center"
                >
                  <div className="home-header-image" />
                  <div id="view">
                    <div className="header-abstract">
                      <img
                        id="home-header-prime-logo"
                        src={primeLogo}
                        alt="LOGO IMG"
                      />
                      <Header as="h1" inverted data-aos="zoom-in">
                        Bringing Gigabit speed full fibre to the heart of
                        London
                      </Header>
                    </div>
                    <Link
                      className="home-scroll-button"
                      to="navigation"
                      smooth={true}
                      duration={500}
                    >
                      <Image
                        src={arrowDown}
                        data-aos-anchor-placement="view"
                        className="home-button-scroll-down"
                        data-aos="fade-down"
                        data-aos-duration="1000"
                      />
                    </Link>
                  </div>
                </Segment>
            }
            <Element name="navigation">
              <Navigation
                title="PRIME FIBRE"
                items={newItems}
                currentRole={init.role}
                baseURL={this.config.base_url}
                logoURL={navigationLogo}
                residentialBusinessValue={
                  residentialBusinessEnabledPaths
                    .indexOf(location.pathname.split('/').slice(-1)[0]) >= 0 &&
                      this.props.customerType
                }
                handleResidentialBusiness={
                  residentialBusinessEnabledPaths
                    .indexOf(location.pathname.split('/').slice(-1)[0]) >= 0 &&
                      this.handleItemClick
                }
              />
            </Element>
            <div className="page-content">
              {content}
            </div>
            {modal}
            {
              content &&
                <Footer
                  links={links}
                  release={release}
                  text="© Prime Fibre All Rights Reserved."
                  color="#0D0D1D"
                  textColor="#FFF"
                  textAlignment="left"
                  linksAlignment="right"
                  popupAlignment="center"
                />
            }
            {
              // The cookie banner is shown:
              (
                // If the page is scrolled until navigation bar reach the top
                this.state.showCookieBanner &&
                (
                  // On every page different from the homepage and the privacy-policy
                  location.pathname.replace(/\//, '') !== `${this.config.base_url.replace(/\//, '')}privacy-policy`
                )
              ) &&
              <CookieBanner />
            }
          </div>
        </IntlProvider>
      );
    } else {
      return (
        <Dimmer className="dimmer-fixed" active inverted>
          <Loader>Loading</Loader>
        </Dimmer>
      );
    }
  }
}

App.propTypes = {
  init: PropTypes.object.isRequired,
  location: PropTypes.object,
  children: PropTypes.object
};

const mapStateToProps = state => ({
  init: state.init,
  lang: state.lang,
  userLang: state.userLang,
  order: state.order,
  customerType: state.customerType
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    requestOrderGet,
    requestShowSpinner,
    requestHideSpinner,
    requestLogout,
    loadLang,
    userLang,
    toggleCustomerType,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
