export const REQUEST_SHOW_SPINNER = "REQUEST_SHOW_SPINNER";
export const RECEIVE_SHOW_SPINNER = "RECEIVE_SHOW_SPINNER";
export const REQUEST_HIDE_SPINNER = "REQUEST_HIDE_SPINNER";

export const requestShowSpinner = () => ({
  type: REQUEST_SHOW_SPINNER
});

export const receiveShowSpinner = () => ({
  type: RECEIVE_SHOW_SPINNER,
  showSpinner: true
});

export const requestHideSpinner = () => ({
  type: REQUEST_HIDE_SPINNER
});
