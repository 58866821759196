import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import _ from "lodash";
import { Router, Route, browserHistory, IndexRoute } from "react-router";
import { syncHistoryWithStore } from "react-router-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { fetchMyProfile } from "./utils/api/";
import { receiveInitData } from "./actions/init.actions";
import { loadState, saveState } from "./utils/persist";
import mySaga from "./sagas/index.saga.js";
import request from "./utils/config_request";
import rootReducer from "./reducers/index";
import "./index.css";
import App from "./containers/App";

import ServiceMessage from "./components/ServiceMessage";
import asyncComponent from "./components/AsyncComponent";
const CoveragePage = asyncComponent(() => import("./containers/CoveragePage"));
const ServicesPage = asyncComponent(() => import("./containers/ServicesPage"));
const Services = asyncComponent(() => import("./containers/MyServices"));
const Places = asyncComponent(() => import("./containers/Places"));
const TermsConditions = asyncComponent(() =>
  import("./containers/TermsConditions")
);
const PrivacyPolicy = asyncComponent(() =>
  import("./containers/PrivacyPolicy")
);
const Home = asyncComponent(() => import("./containers/Home"));
const ContactUsPage = asyncComponent(() =>
  import("./containers/ContactUsPage")
);
const FaqPage = asyncComponent(() => import("./containers/FaqPage"));
const LoginPage = asyncComponent(() => import("./containers/LoginPage"));
const SignupPage = asyncComponent(() => import("./containers/SignupPage"));
const NotFoundPage = asyncComponent(() => import("./containers/NotFoundPage"));
const UnauthorizedProfile = asyncComponent(() =>
  import("./containers/UnauthorizedProfile")
);
const InstallerPage = asyncComponent(() =>
  import("./containers/InstallerPage")
);
const ConfirmPage = asyncComponent(() => import("./containers/ConfirmPage"));
const Profile = asyncComponent(() => import("./containers/Profile"));
const RecoveryPasswordPage = asyncComponent(() =>
  import("./containers/RecoveryPasswordPage")
);
const ChangePassword = asyncComponent(() =>
  import("./containers/ChangePassword")
);

const release = request("./release.json", {
  headers: { Accept: "application/json", "Content-Type": "application/json" },
});
const config = request("./config.json", {
  headers: { Accept: "application/json", "Content-Type": "application/json" },
});

Promise.all([release, config]).then((value) => {
  if (value[0].err || value[1].err) {
    ReactDOM.render(
      <>
        <p>Check syntax of build and/or config</p>
        <p>{value[0].err}</p>
        <p>{value[1].err}</p>
      </>,
      document.getElementById("root")
    );
  } else {
    const build = value[0].data || {};
    console.log("build", build);
    sessionStorage.setItem("build", JSON.stringify(build));

    const loaded_config = value[1].data || {};
    const config = Object.assign(
      {
        base_url: "/",
        countries_lookup: "uk",
        api: `${window.location.protocol}//${
          window.location.hostname
        }${loaded_config.base_url || "/"}api/v1`,
        google_map_key: "AIzaSyDtegbst1hmSbCYa8GwPTtCpsWfnB5PxJI",
        languages: [{ id: "en", title: "EN", name: "EN", flag: "gb" }],
        currency: "GBP",
        currency_format: "$ 0,0[.]00",
        currency_delimiter_thousands: ".",
        currency_delimiter_decimal: ",",
        initial_sales_lead_ordering: {
          home_drop: false,
          service: true,
        },
      },
      loaded_config
    );

    sessionStorage.setItem("config", JSON.stringify(config));

    fetchMyProfile().then((initData) => {
      if (initData) {
        const sagaMiddleware = createSagaMiddleware();

        const generalReducer = (state, action) => {
          //On user logout reset STATE except for routing - routing MUST stay - no router no party!
          if (action.type === "REQUEST_LOGOUT") {
            const { routing, lang, userlang, order } = state;
            state = { routing, lang, userlang, order };
          }
          return rootReducer(state, action);
        };

        const persistedState = loadState(config.api);

        const store = createStore(
          generalReducer,
          persistedState,
          composeWithDevTools(
            // DevTool
            applyMiddleware(sagaMiddleware)
          )
        );

        store.subscribe(() => {
          saveState(
            {
              init: _.isEmpty(store.getState().init)
                ? initData
                : store.getState().init,
              login: store.getState().login,
            },
            config.api
          );
        });

        if (_.isEmpty(store.getState().init)) {
          store.dispatch(receiveInitData(initData));
        }

        const history = syncHistoryWithStore(browserHistory, store);
        sagaMiddleware.run(mySaga);

        ReactDOM.render(
          <Provider store={store}>
            <div className="router-wrapper">
              <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
                <Route path={config.base_url} component={App}>
                  <IndexRoute component={Home} />
                  <Route
                    path={`${config.base_url}contact-us`}
                    component={ContactUsPage}
                  />
                  <Route path={`${config.base_url}faq`} component={FaqPage} />
                  <Route
                    path={`${config.base_url}services`}
                    component={ServicesPage}
                  />
                  <Route
                    path={`${config.base_url}coverage`}
                    component={CoveragePage}
                  />
                  <Route
                    path={`${config.base_url}my-services`}
                    component={Services}
                  />
                  <Route
                    path={`${config.base_url}my-places`}
                    component={Places}
                  />
                  <Route
                    path={`${config.base_url}installer`}
                    component={InstallerPage}
                  />
                  <Route
                    path={`${config.base_url}login`}
                    component={LoginPage}
                  />
                  <Route
                    path={`${config.base_url}signup`}
                    component={SignupPage}
                  />
                  <Route
                    path={`${config.base_url}sales_lead`}
                    component={ConfirmPage}
                  />
                  <Route
                    path={`${config.base_url}profile`}
                    component={Profile}
                  />
                  <Route
                    path={`${config.base_url}terms`}
                    component={TermsConditions}
                  />
                  <Route
                    path={`${config.base_url}privacy-policy`}
                    component={PrivacyPolicy}
                  />
                  <Route
                    path={`${config.base_url}customer_password`}
                    component={RecoveryPasswordPage}
                  />
                  <Route
                    path={`${config.base_url}change-password`}
                    component={ChangePassword}
                  />
                  <Route
                    path={`${config.base_url}unauthorized`}
                    component={UnauthorizedProfile}
                  />
                  <Route path="*" component={NotFoundPage} />
                </Route>
              </Router>
            </div>
          </Provider>,
          document.getElementById("root")
        );
      } else {
        ReactDOM.render(<ServiceMessage />, document.getElementById("root"));
      }
    });
  }
});
