import {
  RECEIVE_SHOW_SPINNER,
  REQUEST_HIDE_SPINNER
} from "../actions/spinner.actions";

const initialState = {
  showSpinner: false
};

export default (state = initialState, { type, showSpinner }) => {
  switch (type) {
    case RECEIVE_SHOW_SPINNER:
      return {
        ...state,
        showSpinner: showSpinner
      };
    case REQUEST_HIDE_SPINNER:
      return initialState;
    default:
      return state;
  }
};
