import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { reducer as formReducer } from "redux-form";

import init from "./init.reducer";
import login from "./login.reducer";
import order from "./order.reducer";
import modal from "./modal.reducer";
import spinner from "./spinner.reducer";
import service from "./service.reducer";
import object from "./object.reducer";
import lang from "./lang.reducer";
import userlang from "./userLang.reducer";
import customerType from "./customerType.reducer";

export default combineReducers({
  init,
  login,
  order,
  modal,
  spinner,
  service,
  object,
  lang,
  userlang,
  customerType,
  routing: routerReducer,
  form: formReducer
});
