import {
  RECEIVE_SHOW_MODAL,
  REQUEST_HIDE_MODAL
} from "../actions/modal.actions";

const initialState = {
  showModal: false,
  message: "",
  status: null,
  modalType: ""
};
export default (state = initialState, { type, showModal, data, modalType }) => {
  switch (type) {
    case RECEIVE_SHOW_MODAL:
      return {
        ...state,
        showModal: showModal,
        modalType: modalType,
        data
      };
    case REQUEST_HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
};
