import { RECEIVE_OBJECT } from "../actions/object.actions";

export default (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_OBJECT:
      return data;
    default:
      return state;
  }
};
